import 'jquery.js';
import 'bootstrap/js/dropdown.js';
import 'bootstrap-select';
//import 'bootstrap-select/dist/css/bootstrap-select.css';
import './bootstrap-select.css'
import { fillBootstrapSelectWithAjax, setSelectpickerVal } from './bootstrap-select-utils.js';

(function (root, factory) {
    if (root === undefined && window !== undefined) root = window;
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module unless amdModuleId is set
        define(["jquery"], function (a0) {
            return (factory(a0));
        });
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory(require("jquery"));
    } else {
        factory(root["jQuery"]);
    }
}(this,
    /**
     * 
     * @param {JQueryStatic} jQuery
     * @returns
     */
    function (jQuery) {
        (function ($) {
            let sp = $.fn.selectpicker//.Constructor
            $.fn.selectpicker.Constructor.prototype.checkRequired = function () {
                var that = this;
                if (this.$element[0].required) {
                    this.$element.on('invalid.bs.select', function () {
                        that.$button[0].classList.add('bs-invalid');

                        that.$element
                            .on('shown.bs.select' + '.invalid', function () {
                                that.$element
                                    .val(that.$element.val()) // set the value to hide the validation message in Chrome when menu is opened
                                    .off('shown.bs.select' + '.invalid');
                            })
                            .on('rendered.bs.select', function () {
                                // if select is no longer invalid, remove the bs-invalid class
                                if (this.validity.valid) that.$button[0].classList.remove('bs-invalid');
                                that.$element.off('rendered.bs.select');
                            });

                        that.$button.on('blur.bs.select', function () {
                            that.$element.trigger('focus').trigger('blur');
                            that.$button.off('blur.bs.select');
                        });
                    });
                } else {
                    this.$element.off('invalid.bs.select');
                }
            }
            $.fn.selectpicker.Constructor.prototype.refresh = function () {
                // update options if data attributes have been changed
                var config = $.extend({}, this.options, this.$element.data());
                this.options = config;

                this.checkRequired();
                this.checkDisabled();
                this.buildData();
                this.setStyle();
                this.render();
                this.buildList();
                this.setWidth();

                this.setSize(true);

                this.$element.trigger('refreshed.bs.select');
            }

            return sp
        })(jQuery);

    }))

export {
    fillBootstrapSelectWithAjax,
    setSelectpickerVal,
};