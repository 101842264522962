import { pluralizeAutoSplit } from "./utils";
export const language = window.language;
export const _T = window._T;
export const _F = (resource, ...args) => {
    let result = _T[resource];
    if (!result || !args || args.length == 0)
        return result || '';
    return args.reduce((acc, x, i) => acc.replace(`{${i}}`, `${x}`), result);
};
/**
 * Склоняет количество дней и возвращает полученную строку (без количества дней).
 */
export function pluralizeDays(n) {
    return pluralizeAutoSplit(n, _T.COMMON_DAYS);
}
export function pluralizeHours(n) {
    return pluralizeAutoSplit(n, _T.COMMON_HOURS);
}
export function pluralizePersons(n) {
    return pluralizeAutoSplit(n, _T.COMMON_PERSONS);
}
/**
 * Склоняет локализованную строку и возвращает результат. Результат не содержит количество.
 */
export function pluralizeI18n(type, n) {
    let resource = null;
    switch (type) {
        case 'days':
            resource = _T.COMMON_DAYS;
            break;
        case 'adults':
            resource = _T.COMMON_PLURAL_ADULTS;
            break;
        case 'children':
            resource = _T.COMMON_PLURAL_CHILDREN;
            break;
    }
    if (!resource)
        return '';
    return pluralizeAutoSplit(n, resource);
}
export function formatString(format, args) {
    if (!args)
        return format;
    let str = format;
    for (let i = 0; i < args.length; i++) {
        str = str.replaceAll(`{${i}}`, args[i]);
    }
    return str;
}
