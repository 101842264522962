var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { appUrl, toURLSearchParams } from '../utils';
import { checkSessionEnd, disablePage } from './utils.js';
const defaultFetchOptions = {
    headers: {}
};
function mutateJson(url, method, data, options) {
    const csrf = readCsrfToken();
    options = options || Object.assign(Object.assign({}, defaultFetchOptions), { headers: Object.assign({}, defaultFetchOptions.headers) });
    // Добавление CSRF токена
    if (csrf)
        options.headers['X-XSRF-TOKEN'] = csrf;
    return fetch(appUrl(url), Object.assign(options, {
        method: method,
        headers: Object.assign(options.headers || {}, {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }),
        body: JSON.stringify(data),
    }));
}
function postJson(url, data, options) {
    return mutateJson(url, 'post', data, options);
}
function putJson(url, data, options) {
    return mutateJson(url, 'put', data, options);
}
function getJson(url, query, options) {
    options = options || Object.assign(Object.assign({}, defaultFetchOptions), { headers: Object.assign({}, defaultFetchOptions.headers) });
    let searchParams = null;
    if (!(query instanceof URLSearchParams) && typeof query == 'object' && !Array.isArray(query)) {
        searchParams = toURLSearchParams(query);
    }
    else {
        searchParams = new URLSearchParams(query);
    }
    const url_ = url.indexOf('?') != -1
        ? `${url}&${searchParams.toString()}`
        : `${url}?${searchParams.toString()}`;
    return fetch(appUrl(url_), Object.assign(options, {
        headers: Object.assign(options.headers || {}, {
            'Accept': 'application/json'
        }),
    }));
}
/**
 * Выполняет API запрос к серверу. Использование этого метода подразумевает что сервер вернет
 * ответ в application/json формате.
 *
 * @param {String} method метод обращения к серверу (get | post | put)
 * @param {String} url URL запроса
 * @param {any | object | URLSearchParams} data данные запроса
 * @param {object} options доп. опции для fetch
 */
function api(method, url, data, options) {
    return __awaiter(this, void 0, void 0, function* () {
        let promise = null;
        switch (method.toLowerCase()) {
            case 'post':
                promise = postJson(url, data, options);
                break;
            case 'put':
                promise = putJson(url, data, options);
                break;
            case 'get':
                promise = getJson(url, data, options);
                break;
            default:
                throw new Error('Unknown method: ' + method);
        }
        const result = yield parseJson(promise);
        return result;
    });
}
/**
 * Работает аналогично api(), но блокирует интерфейс.
 */
function apiS(method, url, data, options) {
    return __awaiter(this, void 0, void 0, function* () {
        disablePage(true);
        return api(method, url, data, options).finally(() => disablePage(false));
    });
}
/**
 * Парсит ответ сервера и возвращает объект { data: [...], errors: [...] }.
 * Если ответ содержит ошибки, errors будет содержать непустой список со строками ошибок,
 * в противном случае - errors содержит пустой список.
 *
 * @param {Promise} r Promise содержащий ответ сервера
 */
function parseJson(r) {
    return __awaiter(this, void 0, void 0, function* () {
        let json = null;
        let response;
        try {
            response = yield r;
            // Проверка на вылет из системы.
            if (response.headers.get('Content-Type') == 'text/html') {
                var text = yield response.text();
                checkSessionEnd(text);
                console.warn('Expected application/json but got text/html instead');
                return { response: undefined, errors: ['Logged out'] };
            }
            json = yield response.json();
        }
        catch (err) {
            console.error("Error while reading JSON response", err);
            return { response: undefined, errors: ['Error while parsing server response'] };
        }
        if (typeof json == 'object' && ('errors' in json || 'data' in json)) {
            if (Array.isArray(json.errors) && json.errors.length > 0) {
                return { response: json.data, errors: json.errors };
            }
            else {
                return { response: json.data, errors: null };
            }
        }
        else {
            return { response: json, errors: null };
        }
    });
}
function readCsrfToken() {
    return window.csrfToken || null;
}
export { api, apiS, readCsrfToken, };
