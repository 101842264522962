import moment from 'moment';
import $ from 'jquery';
import { ajaxPost } from 'network/network-old.js';
import 'jquery-components/js-offcanvas';
import 'jquery-components/bootstrap-modal';
import 'jquery-components/bootstrap-select';
import { readCsrfToken } from 'network/network';
import { formatString } from './appI18n';
import { appUrl } from './utils';

// Установка локали в зависимости от выбранного языка для moment.js.
moment.locale(language);

// Общие настройки
$(function () {

    jQuery.ajaxSettings.traditional = true; // Чтобы массивы сериализовались без []
    rebindExpand();
    // Ссылки плавного перемещения
    $("a[data-smooth-scroll]").click(function (evt) {
        evt.preventDefault();
        var hash = $(this).attr("href");
        $("html, body").animate({ scrollTop: $(hash).offset().top }, 300);
    });
});

function rebindExpand() {
    // Ссылки раскрытия области
    $(".expand-link").off("click", expandLink);
    $(".expand-link").on("click", expandLink);
}

function expandLink(evt) {
    evt.preventDefault();
    var link = $(this);
    var srcClass = link.attr("data-src-class");
    var toggleClass = link.attr("data-class");
    $("." + srcClass).toggleClass(toggleClass);
    link.toggleClass("expanded");

}

// https://stackoverflow.com/questions/563406/add-days-to-javascript-date
Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}

// Отображает значок загрузки
function showLoader() {
    $(".wait-loading").addClass("loading");
}

// Скрывает значок загрузки
function hideLoader() {
    $(".wait-loading").removeClass("loading");
}

// Преобразование числа в строку https://stackoverflow.com/questions/5314237/is-there-a-functionality-in-javascript-to-convert-values-into-specific-locale-fo
function numberToString(number, decimalPlaces) {
    var decimalCharacter = (language == "ru" ? "," : "."), thousandsCharacter = (language == "ru" ? " " : ",");
    var symbolLocation = (symbolLocation == undefined || symbolLocation < 1 || symbolLocation == "begin") ? "begin" : "end";
    var decimalPlaces = isNaN(decimalPlaces = Math.abs(decimalPlaces)) ? 2 : decimalPlaces;
    var thisNumber = parseFloat(number.toFixed(decimalPlaces));
    var decimalCharacter = decimalCharacter == undefined ? "." : decimalCharacter;
    var thousandsCharacter = thousandsCharacter == undefined ? "," : thousandsCharacter;
    var pmB = thisNumber < 0 ? "-" : "";
    var i = parseInt(thisNumber = Math.abs(+thisNumber || 0)) + "";
    var j = (j = i.length) > 3 ? j % 3 : 0;
    var retString = pmB;
    retString += (j ? i.substr(0, j) + thousandsCharacter : "")
    retString += i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousandsCharacter);
    retString += (decimalPlaces ? decimalCharacter + (Math.floor(parseFloat(Math.abs(thisNumber - i).toFixed(6)) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)).toFixed(decimalPlaces).slice(decimalPlaces) : "");
    return retString;
};

// Настройка страницы входа
function setupLoginPage() {
    setupLoginControl(function (data) {
        if (data.redirectTo)
            window.location.href = data.redirectTo;
    });
}


// Настройка страницы регистрации
function setupRegistrationPage() {
    $('.registration-form .tab-switcher input[name="tab-chooser"]').on('change', function () {
        $(this).closest('[data-active-tab]').attr('data-active-tab', $(this).val());
    });
    $(".registration-form .reg-data").submit(function (evt) {
        evt.preventDefault();
        $(this).validator("validate");
        if ($(".has-error", this).length)
            return;
        $(".form-error", this).text("");

        switch (parseInt($('input[name="userType"]', this).val())) {
            case 0:
                var submitUrl = appBase + "Home/Registration";
                break;
            case 1:
                var submitUrl = appBase + "Home/ClientRegistration";
                break;
            default:
                return;
        }
        var form = this;
        ajaxPost(submitUrl, $(this).serialize(), function (data) {
            if (data.Error) {
                showErrorDialog(data.Error);
            } else {
                $(form).addClass("dont-show");
                $(".tab-switcher").addClass("dont-show");
                $("~ .register-success", form).removeClass("dont-show");
            }
        });
    });

    if ($('[name="countryId"]')) {
        ajaxGet(appBase + "Home/GetCountries", {}, function (data) {
            if (Array.isArray(data.data)) {
                updateBootstrapSelect($('[name="countryId"]'), undefined, data.data.map(x => ([x.id, x.name])));
            }
        });
        $('[name="countryId"]').change(function (evt) {
            var value = evt.target.value;
            ajaxGet(appBase + "Home/GetCities", { countryId: value }, function (data) {
                if (Array.isArray(data.data)) {
                    updateBootstrapSelect($('[name="cityId"]'), undefined, data.data.map(x => ([x.id, x.name])));
                }
            });
        })
    };
}

function updateBootstrapSelect($el, value, optionList) {
    // Убрать все дочерние <option> элементы кроме <option> с пустым value. 
    $el.children().filter(function (_, x) { return !!$(x).val() }).remove();

    for (const [optionValue, text] of optionList)
        $el.append($('<option>').attr('value', optionValue).text(text));

    $el.selectpicker('refresh');
    $el.selectpicker('val', value);
}

// Настройка стандартных форм, таких как: восстановление пароля
function setupMiniForm(submitUrl) {
    $('.mini-form form').first().on('submit', function (e) {
        e.preventDefault();
        $(this).validator('validate');
        if ($(".has-error", this).length)
            return;
        $(".form-error", this).text("");
        var form = this;
        ajaxPost(submitUrl, $(this).serialize(), function (data) {
            $(form).closest('.mini-form').addClass('displaying-message');
            if (data.Error) {
                $('.form-error', form).text(data.Error);
            } else {
                $(form).addClass("dont-show");
                $("~ .message", form).removeClass("dont-show");
            }
        });
    });
    $('.mini-form input').on('input', function () {
        $(".form-error").text("");
    });
}

function setupPasswordRecoveryStage2(submitUrl) {
    function checkIfPasswordsMatch() {
        return $('input[name="password"]').val() === $('input[name="reenter-password"]').val();
    }
    function errorOnPasswordMismatch(dontBeSmart) {
        if (!dontBeSmart && !$('input[name="reenter-password"]').val())
            return;
        if (!checkIfPasswordsMatch()) {
            $('.form-error').text($('input[name="reenter-password"]').data('error'));
        } else {
            $('.form-error').text('');
        }
    }

    setupMiniForm(submitUrl);
    $('input[name="reenter-password"]').on('blur', function () { errorOnPasswordMismatch(); });
    $('input[name="password"]').on('blur', function () { errorOnPasswordMismatch(); });
    $('.mini-form form [type="submit"]').first().on('click', function (e) {
        if (!checkIfPasswordsMatch()) {
            errorOnPasswordMismatch(true);
            e.preventDefault();
        }
    });
}

// Настройка мастер-страницы
window.languageSelectedEvent = null;
function setupLayout() {
    if ($('#loginUrl, .loginUrl').length) {
        $('#loginUrl, .loginUrl').each((i, el) => {
            let href = $(el).prop('href');
            console.log(href);
            href = href + '?returnUrl=' + encodeURIComponent(document.baseURI)
            console.log(href);
            $(el).prop('href', href);
        });
        //let href = $('#loginUrl').prop('href');
        //console.log(href);
        //href = href + '?returnUrl=' + encodeURIComponent(document.baseURI)
        //console.log(href);
        //$('#loginUrl').prop('href', href);
    }

    // Мобильное меню.
    $('#offcanvas-menu').offcanvas({
        modifiers: 'right, overlay',
        triggerButton: '#open-offcanvas-menu',
        onInit: function () {
            var items = [
                $('header .sub-menu').clone().get(0),
                $('header .userlogin').clone().get(0),
                $('<hr/>').get(0),
                $('header .top-menu').clone().get(0),
                $('header .lang').clone().get(0),
                $('header .global-currency').clone().get(0),
            ].filter(x => !!x);
            $('#offcanvas-menu').append(items);
        }
    });
    // Выбор языка
    $(".nav.lang a.choose-lang").click(function (evt) {
        evt.preventDefault();
        var lang = $(this).attr("data-lang");
        document.cookie = "lang=" + lang + "; path=/";
        if (window.languageSelectedEvent)
            window.languageSelectedEvent(lang);
        else
            window.location.reload(true);
    });
    $(".nav.global-currency a.choose-global-currency").click(function (evt) {
        evt.preventDefault();

        var currencyID = $(this).attr("data-currency");
        const event = new CustomEvent('currencychange', {
            cancelable: true,
            detail: { currencyID: currencyID }
        });

        document.cookie = "Currency=" + currencyID + "; path=/";

        if (document.dispatchEvent(event)) {
            window.location.reload();
        } else {
            $(".nav.global-currency .current-global-currency").text($(this).text());
        }
    });
    $('#offcanvas-menu .lang a').click(function (evt) {
        evt.preventDefault();
        var offcanvas = $('#offcanvas-menu').get(0);
        var dropdown = $('.dropdown-menu', $(this).parent()).get(0);
        if (offcanvas !== undefined && dropdown !== undefined) {
            setTimeout(function () {
                var containerRect = offcanvas.getBoundingClientRect();
                var rect = dropdown.getBoundingClientRect();
                if (containerRect.bottom >= rect.bottom)
                    return;
                var offset = $(offcanvas).scrollTop() + rect.bottom - containerRect.bottom + 50;
                $(offcanvas).animate({ scrollTop: offset }, 100);
            }, 0);
        }
    });
}

// Применяет значения из строки html к элементам с классом .ajax-replace
function mergeControls(html, fullReplace = false) {
    if (typeof html == "string") html = $(html);
    html.find(".ajax-replace").each(function (idx, srcElt) {
        srcElt = $(srcElt);
        var dstElt = document.getElementById(srcElt.attr("id"));
        if (!dstElt) return;
        dstElt = $(dstElt);
        if (fullReplace || srcElt.hasClass('ajax-full-replace'))
            dstElt.replaceWith(srcElt);
        else
            dstElt.html(srcElt.html());
    });
}


// Удаляет из полей символы при вводе, опционально переводя в верхний регистр 
function removeFromInput(elts, regex, toUpper) {
    elts.on("input", function () {
        var val = $(this).val();
        var valNew = val.replace(regex, "");
        if (toUpper) valNew = valNew.toUpperCase();
        if (val != valNew) {
            var c = this.selectionStart;
            $(this).val(valNew);
            this.setSelectionRange(c, c);
        }
    });
}


// Отображает диалог с ошибкой
function showErrorDialog(message, title = undefined, html = false) {
    if (html)
        $("#errorDialog .modal-body").html(message);
    else
        $("#errorDialog .modal-body").text(message);
    if (title)
        $("#errorDialog .title-text").text(title);

    $("#errorDialog").modal();
}

function submitForm(targetUrl, params) {
    var $form = $('<form></form>');
    $form.attr('action', targetUrl);
    $form.attr('method', 'POST');

    if (!params["csrfToken"])
        params["csrfToken"] = readCsrfToken();

    var paramEntries = Object.entries(params);

    for (var i = 0; i < paramEntries.length; i++) {
        var name = paramEntries[i][0];
        var val = paramEntries[i][1];
        var $el = $('<input type="hidden"></input>');

        $el.attr('name', name);
        $el.attr('value', val);
        $form.append($el);
    }

    $('body').append($form);
    $form.submit();
}

function getFormAsObject($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}

// Отключает проверку элементов формы при их изменении - она будет выполняться только при отправке формы
/*function disableFormInputChangeValidation(formSelector) {
    $(formSelector).validator().off("change.bs.validator");
}*/

const clamp = function (val, min, max) {
    if (val < min)
        return min;
    if (val > max)
        return max;
    return val;
}

export {
    setupLayout,
    numberToString,
    showLoader,
    hideLoader,
    mergeControls,
    removeFromInput,
    setupLoginPage,
    setupRegistrationPage,
    setupPasswordRecoveryStage2,
    showErrorDialog,
    moment,
    submitForm,
    getFormAsObject,
    clamp,
    rebindExpand,
}