import $ from 'jquery.js';
import 'jquery-components/ekko-lightbox';

// Настройка просмотра картинок
function setupImagePreview() {
    $(document).off('click', '[data-toggle="lightbox"]', click);
    $(document).on('click', '[data-toggle="lightbox"]', click);
}

function click (event) {
    event.preventDefault();
    $(this).ekkoLightbox({ alwaysShowClose: true });
}

export {
    setupImagePreview
}