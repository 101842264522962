import 'bootstrap-notify';
import { hideLoader, numberToString, rebindExpand, setupLayout, showLoader } from 'common.js';
import { setupLoginControl } from 'inlineLogin.js';
import { setupImagePreview } from 'jquery-common/image-previews';
import { setupPopovers } from 'jquery-common/popovers';
import 'jquery-components/bootstrap-button';
import 'jquery-components/bootstrap-collapse';
import 'jquery-components/bootstrap-datepicker';
import 'jquery-components/bootstrap-datetimepicker';
import 'jquery-components/bootstrap-popover';
import { fillBootstrapSelectWithAjax } from 'jquery-components/bootstrap-select';
import 'jquery-components/bootstrap-validator';
import 'jquery-ui/ui/widgets/slider.js';
import $ from 'jquery.js';
import { api } from 'network/network';
import { ajaxGet, ajaxPost } from 'network/network-old.js';
import { toURLSearchParams } from '../../include/utils';

// Получает атрибуты data- из узла и записыват их в объект
function getDataAttributes(node, obj) {
    if (!node || !node.attributes) return;
    var attrs = node.attributes;
    for (var i = 0; i < attrs.length; i++) {
        var name = attrs[i].name;
        if(name.length>5 && name.substring(0,5) == "data-")
            obj[name.substring(5)] = attrs[i].value;
    }
}

// Настройка полей выбора даты при поиске отелей
function setupHotelSearchDates(checkoutHint, checkoutOffset) {
    // Настройка полей выбора даты
    $(".input-daterange").datepicker({
        todayBtn: "linked",
        language: language,
        startDate: minStartDate,
        orientation: "bottom auto",
        todayHighlight: true,
        autoclose: true,
        keepEmptyValues: true
    });
    if (checkoutHint) {
        $("#coutField").on('show', function (e) {
            if (!$('.datepicker .message-float').length) {
                $('.datepicker').prepend('<div class="message-float">' + checkoutHint + '</div>');
            }
            $('.datepicker .message-float').show();
        });
    }

    // Установка конца диапазона на следующий день
    $("#cinField").on("changeDate", function (evt) {
        if (!evt.date || $("#coutField").datepicker("getDate") > evt.date)
            return;
        $("#coutField").datepicker("setDate", evt.date.addDays(checkoutOffset || 1));
    });
}


// Выбор количества номеров
function showHideRoomSelectors() {
    var roomNo = parseInt($("#roomCount").selectpicker("val"));
    $("form .room").addClass("dont-show");
    for (var i = 1; i <= roomNo; i++)
        $("form .room" + i.toString()).removeClass("dont-show");
};


// Выбор количества детей
function showHideChildrenAges() {
    $.each($("form .room"), function (index, row) {
        var childCount = parseInt($(".child-count.selectpicker", row).selectpicker("val"));
        $(".child", row).removeClass("dont-show");
        for (var child = childCount + 1; child <= 4; child++)
            $(".child" + child.toString(), row).addClass("dont-show");
    });
};


// Настройка выбора номеров и людей
function setupHotelSearchPeople() {
    $("#roomCount").on("changed.bs.select", showHideRoomSelectors);
    showHideRoomSelectors();
    $(".child-count.selectpicker").on("changed.bs.select", showHideChildrenAges);
    showHideChildrenAges();
}


// Настройка страницы поиска отелей
function setupHotelSearch(checkoutHint, checkoutOffset) {
    $(function () {
        setupHotelSearchDates(checkoutHint, checkoutOffset);
        // Поле страны
        $("#countryId").on("changed.bs.select", function (e) {
            var countryId = $("#countryId").selectpicker("val");
            if (countryId) {
                $("#cityId").prop("disabled", false);
                var pc = prevCityId;
                fillBootstrapSelectWithAjax("cityId", "Hotel/GetCities", { CountryId: countryId }, prevCityId,
                    function () { if (pc) $("#bookingForm").validator("validate"); });
                prevCityId = undefined;
            } else {
                $("#cityId").prop("disabled", true).selectpicker("refresh");
            }
        });
        // Страна могла быть выбрана при загрузке страницы, поэтому надо сразу проверить
        if ($("#countryId").val())
            setTimeout(function () { $("#countryId").trigger("changed.bs.select"); }, 500);
        else
            prevCityId = null;

        // Заполнение списка отелей
        var fillHotels = function () {
            var cityId = $("#cityId").selectpicker("val");
            var hotelCategoryId = $("#hotelCategoryId").selectpicker("val");
            if (!cityId)
                $("#hotelId").prop("disabled", true);
            else {
                fillBootstrapSelectWithAjax("hotelId", "Hotel/GetHotels", { CityId: cityId, HotelCategoryId: hotelCategoryId });
                $("#hotelId").prop("disabled", false);
            }
        };

        // Поле города и категорий
        $("#cityId").prop("disabled", true);
        $("#hotelId").prop("disabled", true);
        $("#cityId").on("changed.bs.select", fillHotels);
        $("#hotelCategoryId").on("changed.bs.select", fillHotels);

        setupHotelSearchPeople();

        // Очистка полей
        $("#clrBtn").click(function (evt) {
            evt.preventDefault();
            $("#cityId").selectpicker("val", null);
            $("#countryId").selectpicker("val", null);
            $("#hotelCategoryId").selectpicker("val", null);
            $("#hotelId").selectpicker("val", null);
            $("#mealId").selectpicker("val", null);
            $("#excHotReq").prop("checked", false);
            $(".input-daterange input").datepicker("clearDates");
            $("#roomCount").selectpicker("val", "1");
            showHideRoomSelectors();
            for (var i = 0; i < 3; i++) {
                $("#rooms" + i.toString() + "adults").selectpicker("val", "1");
                $("#rooms" + i.toString() + "childs").selectpicker("val", "0");
            }
            showHideChildrenAges();
        });

        // Отображение ранее выбранных номеров, если они есть
        if (prevRooms && prevRooms.length && prevRooms.length < 4) {
            $("#roomCount").selectpicker("val", prevRooms.length);
            showHideRoomSelectors();
            for (var roomNo = 0; roomNo < prevRooms.length; roomNo++) {
                var room = prevRooms[roomNo];
                $("#rooms" + roomNo.toString() + "adults").selectpicker("val", room.Adults);
                $("#rooms" + roomNo.toString() + "childs").selectpicker("val", room.Childs);
                for (var child = 0; child < room.Childs; child++)
                    $("#rooms" + roomNo.toString() + "ages" + child.toString()).selectpicker("val", room.Ages[child]);
            }
            showHideChildrenAges();
        }

        // Отображение процесса загрузки
        $("#bookingForm").validator().on("submit", function (e) {
            if (!e.isDefaultPrevented()) {
                setTimeout(showLoader, 1000);
            }
        })
        //disableFormInputChangeValidation("#bookingForm");

        // Сохранение параметров при переключении языка
        window.languageSelectedEvent = function () {
            var data = $("#bookingForm").serialize();
            ajaxGet(appBase + "Hotel/StoreSearchResultRq", data, function (res) { window.location.reload(true); });
        };
    });
}


// Дофильтрация результатов поиска
function hotelSearchResultApplyFilter() {
    setupHotelSearchResult.partiallyDisplayed = false;
    var hotels = hotelSearchResultApplyFilter.hotels;

    // Получение фильтра
    var sliderValues = $("#priceSlider").slider("option", "values");
    var filter = {
        categories: $.map($("#hotelCategory input[type=checkbox]:checked"), function (v) { return $(v).attr("data-id"); }),
        hotelId: $("#filterHotelId").selectpicker("val"),
        periodType: $("#hotelPrice input[name=periodType]:checked").val(),
        priceType: $("#hotelPrice input[name=priceType]:checked").val(),
        minSum: sliderValues[0],
        maxSum: sliderValues[1],
    };
    var sumMultiplier = 1.0 / (filter.periodType == "night" ? totalNights : 1.0);

    var timeoutId = setTimeout(showLoader, 1000);
    window.__bookingPage = 0;
    ajaxGet(appBase + "Hotel/SearchResult", $("#bookingForm").serialize() + '&' + toURLSearchParams({
        SelectedHotelCategories: filter.categories,
        SelectedHotelIds: filter.hotelId ? [filter.hotelId] : undefined,
        SpoOnly: filter.priceType == "spo",
        PricePerNight: filter.periodType == "night",
        MinPrice: filter.minSum || undefined,
        MaxPrice: filter.maxSum || undefined,
    }) + '&page=' + window.__bookingPage, function (html) {
        clearTimeout(timeoutId);
        hideLoader();
        $('#hotelList').attr('data-total-count', $($(html)[0]).attr('data-total-count'))
        $('#hotelList').children('tr').remove();
        $('#hotelList').append(html);
        let total = Number.parseInt($('#hotelList').attr('data-total-count'));
        let pageSize = Number.parseInt($('#hotelList').attr('data-page-size'));
        if (total <= pageSize * (window.__bookingPage + 1))
            $('.load-more-button-container').hide();
        else
            $('.load-more-button-container').show();

        rebindHotelSearchResultEvent();
    });
    return;

    // Фильтрация
    for (var i = 0; i < hotels.length; i++) {
        var hotel = hotels[i];
        hotel.elements.addClass("dont-show");
        if (filter.categories.indexOf(hotel.categoryid) < 0)
            continue;
        if (filter.hotelId && filter.hotelId != hotel.hotelid)
            continue;
        if (filter.priceType == "spo" && hotel.spo != "1")
            continue;

        // Фильтрация по сумме
        var prices = hotel.prices;
        var sumIsGood = false;
        var currentSum = sumMultiplier * parseFloat(hotel.elements.filter(".hotel-title[data-sum]").attr("data-sum"));
        if (false /*currentSum >= filter.minSum && currentSum <= filter.maxSum*/) {
            sumIsGood = true;
        } else {
            var goodPrice = null;
            var possibleRooms = [];
            for (var p = 0; p < prices.length; p++) {
                var price = prices[p];
                if (price.sum * sumMultiplier >= filter.minSum && price.sum * sumMultiplier <= filter.maxSum) {
                    sumIsGood = true;
                    if(!goodPrice)
                        goodPrice = price;
                    // Для хитрой логики фильтрации надо запомнить номер - может быть он не будет выбран прямо сейчас,
                    // Но в принципе для некоторого размещения подходит
                    for (var roomNo = 0; roomNo < price.length; roomNo++)
                        if (possibleRooms.indexOf(price[roomNo].node) < 0)
                            possibleRooms.push(price[roomNo].node);
                 }
            }
            if (sumIsGood) {
                // Выборка номеров, подходящих под фильтр
                var roomRows = hotel.elements.filter(".more-info-" + hotel.hotelid);
                var price = goodPrice;
                for (var ip = 0; ip < roomRows.length; ip++) {
                    var selectedRooms = 0;
                    for (var roomNo = 0; roomNo < price.length; roomNo++)
                        if (price[roomNo].node == roomRows[ip])
                            selectedRooms++;
                    var $rowRoom = $(roomRows[ip]);
                    $rowRoom.find(".selectpicker.room-count").selectpicker("val", selectedRooms);
                    // Элементы, которые даже теоретически не подходят под фильтр, должны быть убраны
                    if(possibleRooms.indexOf(roomRows[ip])<0)
                        $rowRoom.addClass("filtered-sum");
                    else
                        $rowRoom.removeClass("filtered-sum");
                }
                hotelSearchRecalcHotelGroupTitle(hotel.hotelid);
            }
        }
        if (!sumIsGood)
            continue;
        hotel.elements.removeClass("dont-show");
    }

    var totalFound = $(".hotel-title:not(.dont-show)").length;
    $(".hotels-number .count").text(totalFound);
}

var mailSelectionMenu = null;

// Создание меню выбора питания
function createMealSelectionMenu($item, group, curIdx) {
    if (mailSelectionMenu)
        mailSelectionMenu.remove();
    var $menu = $("<ul class='meal-menu'></ul>");
    for (var i = 0; i < group.length; i++) {
        if (i == curIdx)
            continue;
        var $option = $("<li></li>");
        $option.text(group[i].meal);
        (function (group, i) {
            $option.click(function (evt) {
                evt.preventDefault();
                group[curIdx].node.addClass("meal-group-inactive");
                group[i].node.removeClass("meal-group-inactive");
            });
        })(group, i);
        $menu.append($option);
    }
    $item.append($menu);
    mailSelectionMenu = $menu;
}


// Настройка выбора групп питания по отелю
function setupHotelSearchResultMealSelection(hotel) {
    var dets = hotel.elements.filter(".hotel-group-det");
    // Создание групп
    for (var i = 0; i < dets.length; i++) {
        var det = $(dets[i]);
        var group = det.attr("data-type") + " " + det.attr("data-category");
        var meal = det.attr("data-meal")
        var firstInGroup = false;
        if (!hotel.groups) hotel.groups = {};
        if (!hotel.groups[group]) {
            hotel.groups[group] = [];
            firstInGroup = true;
        }
        hotel.groups[group].push({ meal: meal, node: det });
        if (!firstInGroup)
            det.addClass("meal-group-inactive");
    }
    // Выбор питания разрешается только для групп, в которых есть, что выбирать
    for (var groupName in hotel.groups) {
        var g = hotel.groups[groupName];
        if (g.length > 1) {
            for (var gg = 0; gg < g.length; gg++) {
                g[gg].node.addClass("group-selector");
                var $sel = g[gg].node.find(".meal-selector");
                (function ($sel, g, gg) {
                    $sel.click(function (evt) {
                        evt.preventDefault();
                        if (!$sel.hasClass("opened")) {
                            $(".meal-selector.opened").removeClass("opened");
                            $sel.addClass("opened");
                            createMealSelectionMenu($(this), g, gg);
                        } else {
                            $(".meal-selector.opened").removeClass("opened");
                            if (mailSelectionMenu)
                                mailSelectionMenu.remove();
                        }
                    });
                })($sel, g, gg);
            }
        }
    }
}


hotelSearchResultApplyFilter.hotels = [];


// Подготовка данных для фильтров
var priceFilterMin = 0, priceFilterMax = 0;
function prepareHotelSearchFilterData() {
    hotelSearchResultApplyFilter.hotels = [];
    hotelSearchResultApplyFilter.minSum = 100000000;
    hotelSearchResultApplyFilter.maxSum = -100000000;

    var hotels = hotelSearchResultApplyFilter.hotels;
    var hotelRows = $(".hotel-content tr.hotel-title");
    for (var row = 0; row < hotelRows.length; row++) {
        var rowElt = hotelRows[row];
        var hotel = {
            rooms: [],
            prices: []
        };
        getDataAttributes(hotelRows[row], hotel);
        hotel.elements = $(".hotel-content tr.hotel" + hotel.hotelid);
        hotels.push(hotel);
        // Получение цен на все номера в отеле по отдельности
        var totalAccmdVariants = 1;
        for (var i = 1; i <= roomCount; i++) {
            var rooms = [];
            for (var j = 0; j < hotel.elements.length; j++) {
                var roomNos = $(hotel.elements[j]).attr("data-rooms");
                if (roomNos && roomNos.indexOf(i.toString()) >= 0) {
                    var room = {
                        node: hotel.elements[j]
                    };
                    getDataAttributes(hotel.elements[j], room);
                    room.price = parseFloat(room.price);
                    rooms.push(room);
                }
            }
            totalAccmdVariants *= rooms.length;
            hotel.rooms[i - 1] = rooms;
        }
        // Выборка всех возможных цен на искомое размещение
        for (var variant = 0; variant < totalAccmdVariants; variant++) {
            var curVar = variant;
            var priceVariant = [];
            var sum = .0;
            for (var room = 0; room < roomCount; room++) {
                var idx = 0;
                var curRoomVariantsCount = hotel.rooms[room].length;
                if (hotel.rooms[room].length > 1) {
                    idx = curVar % curRoomVariantsCount;
                    curVar = (curVar / curRoomVariantsCount) >> 0; // Целочисленное деление
                }
                priceVariant[room] = hotel.rooms[room][idx];
                sum += priceVariant[room].price;
            }
            priceVariant.sum = sum;
            hotel.prices.push(priceVariant);
            if (sum < hotelSearchResultApplyFilter.minSum) hotelSearchResultApplyFilter.minSum = Math.floor(sum);
            if (sum > hotelSearchResultApplyFilter.maxSum) hotelSearchResultApplyFilter.maxSum = Math.floor(sum + .5);
        }
        // Расчёт групп номеров
        //if (window.groupRoomsSelectMeal)
        //    setupHotelSearchResultMealSelection(hotel);
    }
    priceFilterMin = hotelSearchResultApplyFilter.minSum;
    priceFilterMax = hotelSearchResultApplyFilter.maxSum;
}


// Обработка изменения фильтра
function hotelSearchResultFilterChanged() {
    if (typeof hotelSearchResultFilterChanged.timerId != "undefined")
        clearTimeout(hotelSearchResultFilterChanged.timerId);
    hotelSearchResultFilterChanged.timerId = setTimeout(hotelSearchResultApplyFilter, 500);
}


// Пересчитывает заголовок отеля
function hotelSearchRecalcHotelGroupTitle(hotelId) {
    var allRows = $(".hotel-content .hotel" + hotelId.toString() + "[data-price]");
    var totalSum = 0.0;
    var pushDistinct = function (arr, val) { if (arr.indexOf(val) < 0) arr.push(val); };
    var types = [], categories = [], meals = [];
    var immediateConfirm = true;
    var allRoomCount = 0;
    allRows.each(function (idx, elt) {
        var roomCount = parseFloat($(".room-count.selectpicker", elt).selectpicker("val"));
        elt = $(elt);
        totalSum += parseFloat(elt.attr("data-price")) * roomCount;
        if (roomCount) {
            pushDistinct(types, elt.attr("data-type"));
            pushDistinct(categories, elt.attr("data-category"));
            pushDistinct(meals, elt.attr("data-meal"));
            if (elt.attr("data-availability") == "0")
                immediateConfirm = false;
            allRoomCount += roomCount;
        }
    });
    $(".hotel-content .hotel-title.hotel" + hotelId.toString()).attr("data-sum", totalSum.toString());
    $(".hotel-content .hotel-title.hotel" + hotelId.toString() + " .total-sum").text(numberToString(totalSum, 0));
    $(".hotel-content .hotel-title.hotel" + hotelId.toString() + " .total-meal").html(meals.join("<br/>"));
    $(".hotel-content .hotel-title.hotel" + hotelId.toString() + " .total-type").html(types.join("<br/>"));
    var availability = $(".hotel-content .hotel-title.hotel" + hotelId.toString() + " .room-avail");
    availability.text(immediateConfirm && allRoomCount ? strings.room.available : strings.room.onRequest);
    if (immediateConfirm && allRoomCount)
        availability.removeClass("request").addClass("immediate");
    else
        availability.addClass("request").removeClass("immediate");
}


// Подсказка политики аннуляции
function setupHotelCancellationPolicy() {
    var pars = {
        html: true,
        title: '<i class="fa fa-info" aria-hidden="true"></i><span class="block">' + strings.cancellation.conditions + '</span><button type="button" class="close">&times;</button>',
        content: function () {
            var id = "cancelPopvr" + (++setupHotelSearchResult.fakeId).toString();
            var hotelRow = $(this).closest("tr[data-serialized]");
            var res = "<div id='" + id.toString() + "'></div>";
            var data = {
                priceInternal: hotelRow.attr("data-serialized"),
                checkIn: checkInDateStr
            };
            ajaxPost(appBase + "Hotel/GetHotelRoomCancellationPolicy", data, function (res) {
                if (res && res.CancellationDenied) {
                    var $c = $("#" + id);
                    $c.closest(".popover").find(".popover-title span").text(strings.hotel.noCancelTitle);
                    $c.html(strings.hotel.noCancelDescription);
                    return;
                }
                if (!res || !res.PenaltyCalculationRules || !res.PenaltyCalculationRules.length) {
                    $("#" + id).html("<p class='mb-0'>" + strings.cancellation.free + "</p>");
                    return;
                }
                var msg = "<p class='mb-0'>" + strings.cancellation.penaltyPerRoom + "</p>";
                for (var i = 0; i < res.PenaltyCalculationRules.length; i++) {
                    var rule = res.PenaltyCalculationRules[i];
                    msg += "<br/>" + strings.cancellation.from + " " + rule.FromString + ": " + numberToString(rule.Sum, 2) + " " + rule.Currency.Name;
                }
                msg += "<p class='mb-0 mt-2'>" + strings.cancellation.timezone + "</p>"
                $("#" + id).html(msg);
            });
            return res;
        },
        placement: "bottom",
        container: 'body',
        viewport: { selector: '.hotel-content' },
    };
    setupPopovers(".cancel[data-toggle='popover']",pars);
}

function setupCompulsoryServicesPopover(compulsoryServices) {
    var initializedPopovers = {};

    $('.compulsory').each(function () {
        var $popoverOrigin = $(this);
        var $popoverTemplate = $('#' + $(this).data('popover-id'));
        var $room = $(this).closest('[data-hotel-id]');
        var hotelId = $room.data('hotel-id');
        var svcDescriptor = compulsoryServices.Services[hotelId];
        var accomodation = $room.data('accomodation');
        var paramEntry = JSON.stringify({ hotelId: hotelId, accomodation: accomodation });
        if (!$popoverTemplate.length || hotelId === undefined || svcDescriptor === undefined)
            return;

        $(this).off('click');
        $(this).on('click', function () {
            if ($popoverOrigin.data('bs.popover') !== undefined)
                return;

            if (paramEntry in initializedPopovers) {
                setupPopovers($popoverOrigin.get(0), initializedPopovers[paramEntry]);
                $popoverOrigin.popover('show');
                return;
            }

            var rooms = [{ adults: accomodation.adults, children: accomodation.children, quantity: 1 }];
            fetchCompulsoryServicePrices(hotelId, compulsoryServices, rooms, function (servicePrices) {
                if (servicePrices === null || !servicePrices.length) {
                    initializedPopovers[paramEntry] = {
                        html: true,
                        title: $('.compulsory-popover-no-services-header', $popoverTemplate).get(0).outerHTML,
                        content: $('.compulsory-popover-no-services-body', $popoverTemplate).get(0).outerHTML,
                        placement: "left",
                    };
                    setupPopovers($popoverOrigin.get(0), initializedPopovers[paramEntry]);
                    $popoverOrigin.popover('show');
                    return;
                }
                var $popoverTitle = $('.compulsory-popover-header', $popoverTemplate).clone();
                var $popoverBody = $('.compulsory-popover-body', $popoverTemplate).clone();
                for (var i = 0; i < servicePrices.length; ++i) {
                    var svc = servicePrices[i];
                    var html = '<li>' + svc.price.ValidOn + ' ' + svc.name + ' &ndash; ' + svc.price.Price + ' ' + svc.price.Currency + '</li>';
                    var tree = $.parseHTML(html);
                    $('> ul', $popoverBody).append(tree);
                }

                initializedPopovers[paramEntry] = {
                    html: true,
                    title: $popoverTitle.get(0).outerHTML,
                    content: $popoverBody.get(0).outerHTML,
                    placement: "bottom",
                    container: 'body',
                    viewport: { selector: '.hotel-content' },
                };
                setupPopovers($popoverOrigin.get(0), initializedPopovers[paramEntry]);
                $popoverOrigin.popover('show');
            });
        });
    });
}

function fetchCompulsoryServicePrices(hotelId, compulsoryServices, rooms, callback) {
    var descriptor = compulsoryServices.Services[hotelId];
    var data = {
        hotelId: hotelId,
        currencyId: descriptor.CurrencyId,
        checkIn: descriptor.CheckIn,
        checkOut: descriptor.CheckOut
    };
    // Дополнить параметры списком ID сервисов и дескрипторами проживаний.
    descriptor.Services.reduce(function (acc, x, i) {
        acc['services[' + i + '].id'] = x.Id;
        acc['services[' + i + '].classId'] = x.ClassId;
        return acc;
    }, data);

    if (!Array.isArray(rooms) || rooms.length == 0) {
        callback(null);
        return;
    }
    rooms.reduce(function (acc, x, i) {
        acc['rooms[' + i + '].callerGeneratedId'] = x.callerGeneratedId;
        acc['rooms[' + i + '].quantity'] = x.quantity;
        acc['rooms[' + i + '].adultBuyers'] = x.adults;
        x.children.reduce(function (acc, age, j) {
            acc['rooms[' + i + '].childBuyers[' + j + ']'] = age;
            return acc;
        }, acc);
        return acc;
    }, data);

    ajaxGet(appBase + "Hotel/GetCompulsoryServicePrices", data, function (d) {
        if (Array.isArray(d.ServicePrices)) {
            var arg = d.ServicePrices.map(function (price) {
                var svc = descriptor.Services.find(function (svc) {
                    return svc.Id == price.Id && svc.ClassId == price.ClassId;
                });
                if (svc === undefined)
                    return null;
                return {
                    name: svc.Name,
                    price: price,
                };
            }).filter(function (x) {
                return x !== null;
            });

            callback(arg);
        } else {
            callback(null);
        }
    });
}

// Добавление отеля в корзину
function addHotelToCart(hotelId, compulsoryServices) {
    var hotelRows = $(".hotel-content tr.hotel" + hotelId);
    var rooms = [];
    hotelRows.each(function (idx, elt) {
        var roomCount = parseFloat($(".room-count.selectpicker", elt).selectpicker("val"));
        if (!roomCount)
            return;
        var room = { roomCount: roomCount };
        getDataAttributes(elt, room);
        rooms.push(room);
    });
    if (!rooms.length) {
        $("#hotelPricesLink").addClass("must-select-price");
        return;
    }

    // Континуация процессинга параметров заказа и финальный пост на сервер.
    function continuation(chosenCompulsoryService) {
        var cartData = { searchParamsSerialized: searchParamsSerialized };

        // Разнос выбранного обязательного сервиса по номерам.
        if (chosenCompulsoryService !== undefined && chosenCompulsoryService !== null) {
            var roomPrices = Object.entries(chosenCompulsoryService.price.PerRoom);
            for (var i = 0; i < roomPrices.length; ++i) {
                var roomId = roomPrices[i][0];
                rooms[roomId].compulsoryServiceParams = {
                    id: chosenCompulsoryService.price.Id,
                    classId:  chosenCompulsoryService.price.ClassId,
                    serviceActivationDate: chosenCompulsoryService.price.ValidOnIso,
                    serviceDuration: chosenCompulsoryService.price.Duration,
                };
            }
        }

        for (var i = 0; i < rooms.length; i++)
            cartData["rooms[" + i.toString() + "]"] = JSON.stringify(rooms[i]);
        showLoader();
        api("post", "/Hotel/AddToCart", cartData)
            .then(data => {
                if (!data.response.success) {
                    $.notify({ message: strings.room.unavailableRateError }, { type: "danger", allow_dismiss: true, delay: 15, z_index: 1200 });
                } else {
                    window.location = appBase + "Cart";
                }
            })
            .finally(() => {
                hideLoader();
            });
    }

    // Рассчитать цены на обязательные услуги и показать модал с выбором услуги, если услуг несколько.
    if (compulsoryServices !== undefined && compulsoryServices.Services[hotelId] !== undefined) {
        var roomsXform = rooms.map(function (x, i) {
            var json = JSON.parse(x.accomodation);
            return { callerGeneratedId: i, adults: json.adults, children: json.children, quantity: x.roomCount };
        });
        fetchCompulsoryServicePrices(hotelId, compulsoryServices, roomsXform, function (servicePrices) {
            if (servicePrices === null || servicePrices.length == 0) {
                continuation();
                return;
            } else if (servicePrices.length == 1) {
                // Если доступен только 1 сервис - пропускаем модал.
                continuation(servicePrices[0]);
                return;
            }

            var serviceRadios = servicePrices.map(function (x, id) {
                return '<div class="radio">' +
                        '   <label>' +
                        '       <input type="radio" name="compulsory-service-' + hotelId + '" value="' + id + '">' +
                        '       ' + x.price.ValidOn + ' ' + x.name + ' &ndash; ' + x.price.Price + ' ' + x.price.Currency
                        '   </label>' +
                        '</div>';
            });
            var $modal = $('#compulsory-service-choice-modal');
            $('.service-list-container', $modal).html('').append(serviceRadios);
            $($modal).modal();

            // Включение/отключение кнопки "Выбрать" в зависимости от состояния радио.
            $('[name="compulsory-service-' + hotelId + '"]', $modal).on('change', function () {
                var $checked = $('[name="compulsory-service-' + hotelId + '"]:checked', $modal);
                if ($checked.length) {
                    $('.modal-footer button', $modal).removeAttr('disabled');
                } else {
                    $('.modal-footer button', $modal).attr('disabled', 'disabled');
                }
            });

            // Переход в корзину при выборе сервиса.
            $('.modal-footer button', $modal).on('click', function () {
                var $checked = $('[name="compulsory-service-' + hotelId + '"]:checked', $modal);
                if (!$checked.length) {
                    alert('pls');
                    return;
                }
                var service = servicePrices[parseInt($checked.val())];
                if (service !== undefined) {
                    continuation(service);
                }
            });
        });
    } else {
        continuation();
    }
}


// Настройка страницы результатов поиска
function setupHotelSearchResult(compulsoryServices) {
    setupHotelSearchResult.map = null;
    setupHotelSearchResult.mapMarker = null;
    setupHotelSearchResult.fakeId = 1;
    $(function () {

        // Подписка на события фильтров
        $("#hotelCategory input,#hotelPrice input[type=radio][name=priceType]").change(hotelSearchResultFilterChanged);
        $("#filterHotelId").on("changed.bs.select", hotelSearchResultFilterChanged);
        $("#hotelPrice input[type=radio][name=periodType]").change(function () {
            priceFilterMin = hotelSearchResultApplyFilter.minSum;
            priceFilterMax = hotelSearchResultApplyFilter.maxSum;
            if ($(this).val() == "night") {
                priceFilterMin /= totalNights;
                priceFilterMax /= totalNights;
                priceFilterMin = Math.floor(priceFilterMin);
                priceFilterMax = Math.floor(priceFilterMax + .5);
            }
            var slider = $("#priceSlider");
            slider.slider("option", { min: priceFilterMin, max: priceFilterMax });
            slider.slider("values", 0, priceFilterMin);
            slider.slider("values", 1, priceFilterMax);
            $("#inputPrice1").val(priceFilterMin);
            $("#inputPrice2").val(priceFilterMax);
            hotelSearchResultFilterChanged();
        });

        rebindHotelSearchResultEvent(false);

        // Доотображение отелей - по умолчанию они скрыты чтобы меньше напрягать сервер загрузкой 
        // картинок и клиента отображением разметки
        setupHotelSearchResult.partiallyDisplayed = true;
        $(window).scroll(function () {
            if (!setupHotelSearchResult.partiallyDisplayed)
                return;
            var hotelsPanel = $(".hotel-panel");
            var wnd = $(window);
            if (hotelsPanel.offset().top + hotelsPanel.outerHeight() < wnd.scrollTop() + wnd.height() + 100)
                $(".hotel-content tr.dont-show").slice(0, 20).removeClass("dont-show");
        });

        // Подготовка данных отелей для фильтров
        prepareHotelSearchFilterData();

        // Подписка на события слайдера
        $("#priceSlider").slider({
            range: true,
            min: hotelSearchResultApplyFilter.minSum,
            max: hotelSearchResultApplyFilter.maxSum,
            values: [hotelSearchResultApplyFilter.minSum, hotelSearchResultApplyFilter.maxSum],
            slide: function (event, ui) {
                $("#inputPrice1").val(ui.values[0]);
                $("#inputPrice2").val(ui.values[1]);
                hotelSearchResultFilterChanged();
            }
        });
        $("#inputPrice1").val($("#priceSlider").slider("values", 0));
        $("#inputPrice2").val($("#priceSlider").slider("values", 1));
        $('#inputPrice1').change(function () {
            var val1 = parseFloat($(this).val());
            if (isNaN(val1)) val1 = priceFilterMin;
            $('#priceSlider').slider("values", 0, val1);
            hotelSearchResultFilterChanged();
        });
        $('#inputPrice2').change(function () {
            var val2 = parseFloat($(this).val());
            if (isNaN(val2)) val2 = priceFilterMax;
            $('#priceSlider').slider("values", 1, val2);
            hotelSearchResultFilterChanged();
        });

        // Фиксация заголовка при прокрутке
        $(window).scroll(function () {
            var top = $(window).scrollTop();
            var $crumbs = $(".hotel-form .booking-breadcrumbs");
            if (!$crumbs) return;
            var crumbBottom = $crumbs.offset().top + $crumbs.height();
            if (top > crumbBottom)
                $(".hotel-form .search-params-panel").addClass("fixed");
            else
                $(".hotel-form .search-params-panel").removeClass("fixed");
        });

        window.__bookingPage = 0;
        $('#loadMoreButton').on("click", function (evt) {
            evt.preventDefault();
            window.__bookingPage++;

            // Получение фильтра
            var sliderValues = $("#priceSlider").slider("option", "values");
            var filter = {
                categories: $.map($("#hotelCategory input[type=checkbox]:checked"), function (v) { return $(v).attr("data-id"); }),
                hotelId: $("#filterHotelId").selectpicker("val"),
                periodType: $("#hotelPrice input[name=periodType]:checked").val(),
                priceType: $("#hotelPrice input[name=priceType]:checked").val(),
                minSum: sliderValues[0],
                maxSum: sliderValues[1],
            };
            var sumMultiplier = 1.0 / (filter.periodType == "night" ? totalNights : 1.0);

            var timeoutId = setTimeout(showLoader, 1000);
            ajaxGet(appBase + "Hotel/SearchResult", $("#bookingForm").serialize() + '&' + toURLSearchParams({
                SelectedHotelCategories: filter.categories,
                SelectedHotelIds: filter.hotelId ? [filter.hotelId] : undefined,
                SpoOnly: filter.priceType == "spo",
                PricePerNight: filter.periodType == "night",
                MinPrice: filter.minSum || undefined,
                MaxPrice: filter.maxSum || undefined,
            }) + '&page=' + window.__bookingPage, function (html) {
                clearTimeout(timeoutId);
                hideLoader();
                $('#hotelList').append(html);
                let total = Number.parseInt($('#hotelList').attr('data-total-count'));
                let pageSize = Number.parseInt($('#hotelList').attr('data-page-size'));
                if (total <= pageSize * (window.__bookingPage + 1))
                    $('.load-more-button-container').hide();
                else
                    $('.load-more-button-container').show();

                rebindHotelSearchResultEvent();
                setTimeout(hideLoader, 1000);
            });
        });
    });
}

function rebindHotelSearchResultEvent(rebindCommonEvents = true) {
    if (rebindCommonEvents)
        rebindExpand();

    // Пересчет заголовка группы отеля
    $(".hotel-content .room-count.selectpicker").off("changed.bs.select");
    $(".hotel-content .room-count.selectpicker").on("changed.bs.select", function () {
        var classes = $(this).closest("tr[data-price]").attr("class");
        hotelSearchRecalcHotelGroupTitle(classes.match(/hotel(\d+)/)[1]);
    });

    // Отображение карты (https://developers.google.com/maps/documentation/javascript/adding-a-google-map)
    $(".link.on-map").off("click");
    $(".link.on-map").on("click", function (evt) {
        evt.preventDefault();
        var attrs = {};
        getDataAttributes(this, attrs);
        var point = { lat: parseFloat(attrs.lat), lng: parseFloat(attrs.long) };
        if (!setupHotelSearchResult.map) {
            var map = new google.maps.Map(document.getElementById("googleMapContainer"), {
                zoom: 15,
                center: point
            });
            var marker = new google.maps.Marker({ position: point, map: map, title: attrs.title });
            setupHotelSearchResult.map = map;
            setupHotelSearchResult.mapMarker = marker;
        } else {
            setupHotelSearchResult.mapMarker.setPosition(point);
            setupHotelSearchResult.map.setCenter(point);
            setupHotelSearchResult.mapMarker.setTitle(attrs.title);
        }
        $(".map").fadeIn(300);
    });
    $(".map .close").off("click");
    $(".map .close").on("click", function (evt) {
        evt.preventDefault();
        $(".map").fadeOut(300);
    });

    setupHotelCancellationPolicy();
    setupHotelDetailTabs();
    setupCompulsoryServicesPopover(compulsoryServices);

    $("a.cart-btn").off("click");
    $("a.cart-btn").on("click", function (evt) {
        evt.preventDefault();
        var hotelId = $(this).closest("tr[data-hotelid]").attr("data-hotelid");
        addHotelToCart(hotelId, compulsoryServices);
    });

    // Загрузка описания номеров
    $(".expand-link[data-src-class]").off("click", expandLink)
    $(".expand-link[data-src-class]").on("click", expandLink);

    setupImagePreview();
}

function expandLink (evt) {
    var srcClass = $(evt.currentTarget).attr("data-src-class");
    if (!srcClass) return;
    var srcClassReg = srcClass.match(/more-info-(\d+)/);
    if (!srcClassReg) return;
    loadHotelRoomDetails(parseInt(srcClassReg[1]));
}

// Переключение вкладок и прочие настройки, общие для вкладок с описанием отеля
function setupHotelDetailTabs() {
    $(".hotel-group-det li[data-tab]").off("click");
    $(".hotel-group-det li[data-tab]").on("click", function (evt) {
        evt.preventDefault();
        var $li = $(evt.target);
        var parent = $li.closest(".hotel-group-det");
        parent.find(".tab.active,li[data-tab].active").removeClass("active");
        $li.addClass("active");
        parent.find(".tab." + $li.attr("data-tab")).addClass("active");
    });
    // Подсказка по невозвратным номерам
    var cancelDeniedParams = {
        html: true,
        title: '<i class="fa fa-info" aria-hidden="true"></i><span class="block">' + strings.hotel.noCancelTitle + '</span><button type="button" class="close">&times;</button>',
        content: function () { return strings.hotel.noCancelDescription; }
    };
    setupPopovers(".cancel-denied[data-toggle='popover']", cancelDeniedParams);
    // Подсказка по SPO
    var spoPopoverId = 0;
    var spoPopPars = {
        html: true,
        title: '<i class="fa fa-info" aria-hidden="true"></i><span class="block">SPO</span><button type="button" class="close">&times;</button>',
        content: function () {
            var id = "spoPopvr" + (++spoPopoverId).toString();
            var hotelRow = $(this).closest("tr[data-serialized]");
            var res = "<div id='" + id.toString() + "'><ul><li></li></ul></div>";
            var data = {
                priceInternal: hotelRow.attr("data-serialized")
            };
            ajaxPost(appBase + "Hotel/GetHotelRoomSpoDescription", data, function (d) {
                if (!d || !d.SpoDescriptions)
                    return;
                var $list = $("<ul>");
                for (var i = 0; i < d.SpoDescriptions.length; i++) {
                    var descr = d.SpoDescriptions[i];
                    var $li = $("<li>");
                    $li.append($("<b>").text(descr.Name + (descr.Description?": ":"")));
                    $li.append($("<span>").text(descr.Description));
                    $list.append($li);
                }
                $("#" + id).html($list);
            });
            return res;
        },
        placement: "right"
    };
    setupPopovers(".price-attrs .is-spo[data-toggle='popover']", spoPopPars);
}

// Пустая функция обратного вызова для Google Maps. На всякий случай
function initMap() {
}

// Отображение карты на странице детализации по отелю. В случае статического встраивания координаты передавать нельзя, а надо
function initMapHotelSearchDetails() {
    $(function () {
        var mapBlock = $("#googleMapContainer");
        if (mapBlock.length) {
            mapBlock = mapBlock[0];
            var attrs = {};
            getDataAttributes(mapBlock, attrs);
            var point = { lat: parseFloat(attrs.lat), lng: parseFloat(attrs.long) };
            var map = new google.maps.Map(mapBlock, {
                zoom: 15,
                center: point
            });
            new google.maps.Marker({ position: point, map: map, title: $(".hotel-title > h1").text() });
        }
        setupImagePreview();
    });
}

// Найстрока страницы деталей по отелю
function setupHotelSearchDetails(compulsoryServices) {
    $(function () {
        setupHotelCancellationPolicy();
        setupHotelDetailTabs();
        setupCompulsoryServicesPopover(compulsoryServices);
        $("a.add-to-cart").click(function (evt) {
            evt.preventDefault();
            addHotelToCart(hotelId, compulsoryServices);
        });
        loadHotelRoomDetails(hotelId);
        // Вход в систему
        var $logonBkg = $(".logon-background");
        if ($logonBkg.length) {
            $(".js-localLogon").click(function (evt) { evt.preventDefault(); $logonBkg.show(); });
            $logonBkg.find(".close").click(function (evt) { evt.preventDefault(); $logonBkg.hide(); });
            setupLoginControl(function (data) {
                window.location.reload(true);
            });
        }
        // Выбор номеров для отображения цен
        var $chkBkg = $(".checkin-params-background");
        if ($chkBkg.length) {
            setupHotelSearchDates();
            setupHotelSearchPeople();
            $(".choose-params").click(function (evt) { evt.preventDefault(); $chkBkg.show(); })
            $chkBkg.find(".close").click(function (evt) { evt.preventDefault(); $chkBkg.hide(); });
        }
        // Фиксация кнопки выбора
        var $window = $(window);
        var $priceDiv = $("#hotelPricesLink");
        var $cartBtn = $(".add-to-cart");
        var $priceHdr = $("#hotelPricesLink .hotel-title");
        $(window).scroll(function (evt) {
            var top = $window.scrollTop();
            if (top > $priceDiv.offset().top) {
                $cartBtn.addClass("fixed");
                $cartBtn.css({ left: $priceHdr.offset().left + $priceHdr.outerWidth() });
            } else {
                $cartBtn.removeClass("fixed");
                $cartBtn.css({ left: "" });
            }
        });
        // Расчёт групп номеров
        if (window.groupRoomsSelectMeal) {
            var hotel = { elements: $("table tr.hotel-group-det") };
            setupHotelSearchResultMealSelection(hotel);
        }
        // Выбор количества номеров
        var $hotelPricesLink = $("#hotelPricesLink");
        $(".hotel-content .room-count.selectpicker").on("changed.bs.select", function () {
            $hotelPricesLink.removeClass("must-select-price");
        });
    });
}

// Загружает описание номеров
var openedHotelRoomDetails = {};
function loadHotelRoomDetails(hotelId) {
    hotelId = hotelId.toString();
    var className = ".hotel-group-det.more-info-" + hotelId;
    if (openedHotelRoomDetails[hotelId])
        return;
    showLoader();
    openedHotelRoomDetails[hotelId] = 1;
    var nodes = $("tr" + className);
    var rooms = [];
    for (var idx = 0; idx < nodes.length; idx++) {
        rooms.push($(nodes[idx]).attr("data-serialized"));
    }
    if (!rooms.length) {
        hideLoader();
        return;
    }
    ajaxPost(appBase + "Hotel/GetRoomDetails", { rooms: rooms, hotelId: hotelId }, function (res) {
        var $dn = $(res).find(".description");
        var $ml = $(res).find(".meal");
        var $ht = $(res).find(".hot-attr");
        for (var i = 0; i < nodes.length; i++) {
            $(nodes[i]).find(".room-description > .description").html($($dn[i]).html());
            $(nodes[i]).find(".tab.tabMeal").append($ml[i]);
            $(nodes[i]).find(".tab.tabRoomDescription").append($ht[i]);
        }
    }, "html", true);

    var rq = { Variants: [] };
    for (var i = 0; i < nodes.length; i++) {
        var node = $(nodes[i]);
        var providerId = node.data("provider-id");
        var variantId = node.data("variant-id");

        if (providerId && variantId) {
            var item = rq.Variants.find(x => x.providerId == providerId);
            if (!item) {
                item = { providerId, variantIds: [] };
                rq.Variants.push(item);
            }
            item.variantIds.push(variantId);
        }
    }

    if (rq.Variants.length == 0) {
        hideLoader();
        return;
    }

    api("post", "/Hotel/GetExternalHotelRoomsInfo", rq)
        .then(response => {
            var res = response.response;
            for (var i = 0; i < nodes.length; i++) {
                var node = $(nodes[i]);
                var providerId = node.data("provider-id");
                var variantId = node.data("variant-id");

                if (!providerId || !variantId)
                    continue;

                var info = res.Variants.find(x => x.ProviderId == providerId && x.VariantId == variantId);

                if (!info)
                    continue;

                if (info.Availability != 0) {
                    continue;
                }

                var dayRates = node.find(".price-table .day-rate-item");
                for (var j = 0; j < dayRates.length; j++) {
                    var dayRate = $(dayRates[j]);
                    var nightIndex = dayRate.data("night-index");

                    if (nightIndex < 0 || nightIndex >= info.DayRates.length)
                        continue;

                    dayRate.text(info.DayRates[nightIndex].toLocaleString(window.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
                }

                node.find(".tabRoomDescription").append($("<div class='mb-4'/>").append($("<div/>").append(info.Description).html()));

                /**
                 * @type {ExternalHotelPrice?}
                 */
                let externalHotelPrice = info.ExternalHotelPrice;
                if (externalHotelPrice) {
                    if (externalHotelPrice.Images?.length) {
                        let desc = node.find(".room-description > .description");
                        let gallery = 'g-' + variantId;
                        let a = $(`<a class="image" data-toggle="lightbox" href="${externalHotelPrice.Images[0].Url}" style="background-image:url('${externalHotelPrice.Images[0].Url}')" data-gallery="${gallery}" data-type="image"></a>`);
                        desc.append(a);
                        for (let j = 1; j < externalHotelPrice.Images.length; j++) {
                            let a2 = $(`<a class="dont-show" data-toggle="lightbox" href="${externalHotelPrice.Images[0].Url}" data-gallery="${gallery}" data-type="image"></a>`);
                            desc.append(a2);
                        }
                    }

                    if (externalHotelPrice.AvailableMeals?.length) {
                        for (let meal of externalHotelPrice.AvailableMeals) {
                            node.find(".tab.tabMeal").append($(`<li title="${meal.MealCode}">${meal.MealName} - ${meal.Price} ${meal.Currency}<br /><span class="subtext text-grey">${(meal.Included ? '(включено в стомость)' : '')}</span></li>`));
                        }
                    }

                    //if (externalHotelPrice.PaymentInHotelAvailable)
                    //    node.find('.payment-in-hotel-available').show();
                    //else
                    //    node.find('.payment-in-hotel-available').hide();

                    if (externalHotelPrice.ChildrenMealNeedBookByManager)
                        node.find(".tabRoomDescription").append($("<div/>").append('Дополнительное питание для детей необходимо бронировать отдельно через менеджера'));

                    if (externalHotelPrice.SharedRoom)
                        node.find(".tabRoomDescription").append($("<div/>").append('Номер с подселением'));

                    if (externalHotelPrice.BlockRoom)
                        node.find(".tabRoomDescription").append($("<div/>").append('Номер с удобствами на этаже'));

                    if (externalHotelPrice.RoomWithWindow === false)
                        node.find(".tabRoomDescription").append($("<div/>").append('Номер без окон'));

                    //if (externalHotelPrice.PaymentInHotelAvailable === true)
                    //    node.find(".tabRoomDescription").append($("<div/>").append('Доступна оплата в отеле'));
                    //if (externalHotelPrice.PaymentInHotelAvailable === false)
                    //    node.find(".tabRoomDescription").append($("<div/>").append('Оплата в отеле недоступна'));

                    //if (externalHotelPrice.PaymentOnlineAvailable === true)
                    //    node.find(".tabRoomDescription").append($("<div/>").append('Доступна оплата через агента'));
                    //if (externalHotelPrice.PaymentOnlineAvailable === false)
                    //    node.find(".tabRoomDescription").append($("<div/>").append('Оплата через агента недоступна'));

                    if (externalHotelPrice.CheckInFrom && externalHotelPrice.CheckInTo)
                        node.find(".tabRoomDescription").append($("<div/>").append('<b>Период заезда:</b> ' + externalHotelPrice.CheckInFrom + ' - ' + externalHotelPrice.CheckInTo));

                    if (externalHotelPrice.CheckOutFrom && externalHotelPrice.CheckOutTo)
                        node.find(".tabRoomDescription").append($("<div/>").append('<b>Период выезда:</b> ' + externalHotelPrice.CheckOutFrom + ' - ' + externalHotelPrice.CheckOutTo));
                }
            }
        })
        .finally(() => {
            hideLoader();
        });
}

/**
 * @typedef ExternalHotelPrice
 * @property {number?} Price
 * @property {boolean?} PaymentInHotelAvailable
 * @property {boolean?} PaymentOnlineAvailable
 * @property {boolean?} SharedRoom
 * @property {boolean?} BlockRoom
 * @property {boolean?} RoomWithWindow
 * @property {boolean?} ChildrenMealNeedBookByManager
 * @property {AvailableMealPrice[]?} AvailableMeals
 * @property {{Url: string}[]} Images
 * @property {string?} Comment
 * @property {string?} CheckInBase
 * @property {string?} CheckInFrom
 * @property {string?} CheckInTo
 * @property {string?} CheckOutBase
 * @property {string?} CheckOutFrom
 * @property {string?} CheckOutTo
 */

/**
 * @typedef AvailableMealPrice
 * @property {number} MealId
 * @property {string} MealCode
 * @property {string} MealName
 * @property {boolean} Included
 * @property {number} Price
 * @property {string} Currency
 */

export {
    setupLayout,
    setupHotelSearch,
    setupHotelSearchResult,
    setupHotelSearchDetails,
    initMapHotelSearchDetails,
    initMap
};
