import { readCsrfToken } from "./network/network";
const pluralizeAutoSplit = (count, pattern) => pluralize(count, ...pattern.split(',', 3));
function pluralize(count, word, two, five) {
    const forms = [five, word, two, two, two, five, five, five, five, five];
    const mod100 = count % 100;
    if (mod100 <= 10 || mod100 >= 20)
        return forms[count % 10];
    else if (mod100 && mod100 < 20)
        return five;
}
function formatMoney(value, options) {
    var _a, _b, _c;
    if (isNaN(value))
        return "?";
    var sign = RoundSignForCurrency(options === null || options === void 0 ? void 0 : options.currency);
    if (options === null || options === void 0 ? void 0 : options.localeAware) {
        return new Intl.NumberFormat(window.language || undefined, {
            minimumFractionDigits: (_a = options === null || options === void 0 ? void 0 : options.precision) !== null && _a !== void 0 ? _a : sign,
            maximumFractionDigits: (_b = options === null || options === void 0 ? void 0 : options.precision) !== null && _b !== void 0 ? _b : sign,
        }).format(value);
    }
    else {
        return value.toFixed((_c = options === null || options === void 0 ? void 0 : options.precision) !== null && _c !== void 0 ? _c : sign);
    }
}
function RoundSignForCurrency(currency) {
    if ((currency === null || currency === void 0 ? void 0 : currency.toUpperCase()) == 'RUB')
        return 0;
    return 2;
}
function appUrl(url, searchParams) {
    let result = baseUrl(url);
    if (!searchParams || Object.keys(searchParams).length == 0)
        return result;
    const urlSearchParams = toURLSearchParams(searchParams);
    return result + (result.indexOf('?') == -1 ? "?" : "&") + urlSearchParams.toString();
}
function baseUrl(url) {
    if (url.startsWith('/')) {
        const baseUrl = window.appBase;
        if (!baseUrl)
            return url;
        if (baseUrl.endsWith('/'))
            return baseUrl.replace(/\/+$/, '') + url;
        else
            return baseUrl + url;
    }
    else {
        return url;
    }
}
function toURLSearchParams(obj) {
    const searchParams = new URLSearchParams();
    toURLSearchParamsInner(searchParams, obj);
    return searchParams;
}
function toURLSearchParamsInner(searchParams, obj, prefix = undefined) {
    for (const [k, v] of Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined)) {
        if (Array.isArray(v)) {
            for (let i = 0; i < v.length; i++) {
                const arrayVal = v[i];
                if (typeof arrayVal == "object")
                    toURLSearchParamsInner(searchParams, arrayVal, `${prefix || ''}${k}[${i}].`);
                else
                    searchParams.append(`${prefix || ''}${k}[${i}]`, arrayVal);
            }
        }
        else if (typeof v == "object") {
            toURLSearchParamsInner(searchParams, v, `${prefix || ''}${k}.`);
        }
        else {
            searchParams.append(`${prefix || ''}${k}`, v);
        }
    }
    return searchParams;
}
function isValidEmail(email) {
    return !!email.match(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/gi);
}
const getWeekDays = () => window.weekDays;
const getCurrency = () => window.currency;
const getCurrencies = () => window.currencies;
const openPaymentUrl = (function () {
    function redirect(targetUrl) {
        window.location.href = targetUrl;
    }
    function redirectBlank(targetUrl) {
        window.open(targetUrl, '_blank');
    }
    function submitForm(targetUrl, params, isBlank) {
        var _a, _b;
        let form = document.createElement('form');
        form.setAttribute('action', targetUrl);
        form.setAttribute('method', 'POST');
        if (isBlank)
            form.setAttribute('target', '_blank');
        if (params) {
            if (!params["csrfToken"])
                params["csrfToken"] = readCsrfToken();
            let paramEntries = Object.entries(params);
            for (var i = 0; i < paramEntries.length; i++) {
                let name = paramEntries[i][0];
                let val = (_a = paramEntries[i][1]) !== null && _a !== void 0 ? _a : ((_b = paramEntries[i][1]) !== null && _b !== void 0 ? _b : '').toString();
                let el = document.createElement('input');
                el.setAttribute('type', 'hidden');
                el.setAttribute('name', name);
                el.setAttribute('value', val);
                form.appendChild(el);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }
    return function (paymentUrl) {
        if (paymentUrl.httpVerb == 'post' && paymentUrl.contentType == 'form') {
            submitForm(paymentUrl.targetUrl, paymentUrl.params, paymentUrl.isBlank);
        }
        else if (paymentUrl.httpVerb == 'get' && !paymentUrl.isBlank) {
            redirect(paymentUrl.targetUrl);
        }
        else if (paymentUrl.httpVerb == 'get' && paymentUrl.isBlank) {
            redirectBlank(paymentUrl.targetUrl);
        }
        else {
            console.error('Don\'t know how to handle payment URL', paymentUrl);
            throw new Error('Unsupported payment URL');
        }
    };
})();
function removeEmptyFields(value, removableValues = [null, undefined, NaN]) {
    //function removeEmptyFields<T extends Record<string, any>>(value: T, removableValues: any[] = [null, undefined, NaN]): Partial<T> {
    return Object.fromEntries(Object.keys(value).filter(key => !removableValues.find(r => r === value[key])).map(key => [key, value[key]]));
}
export { appUrl, pluralize, pluralizeAutoSplit, formatMoney, toURLSearchParams, toURLSearchParamsInner, isValidEmail, getWeekDays, getCurrency, getCurrencies, openPaymentUrl, removeEmptyFields, };
