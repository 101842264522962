import $ from 'jquery.js';
import 'jquery-components/bootstrap-popover';

// Настраивает всплывающие окна
function setupPopovers(query, pars) {
    if (pars)
        pars.trigger = "manual";
    // Ручное открытие окна
    $(query).off("click")
    $(query).on("click", function (evt) {
        evt.preventDefault();
        var $this = $(this);
        var thisNode = this;
        $("[data-toggle='popover'][aria-describedby]").each(function (idx, elt) {
            if (elt != thisNode)
                $(elt).popover("hide");
        });
        $this.popover("toggle");
    }).popover(pars);
    // Закрытие по клику где угодно
    $(document).off("mousedown", closePopover);
    $(document).on("mousedown", closePopover);
}

/**
 * 
 * @param {MouseEvent} e
 * @returns
 */
function closePopover (e) {
    var target = $(e.target);
    if (target.attr("data-toggle") == "popover" || target.closest("[data-toggle='popover']").length)
        return;
    if (!target.closest(".popover").length || target.is(".popover .close"))
        $("[data-toggle='popover']").popover("hide");
}

export { setupPopovers }