import { ajaxPost } from 'network/network-old.js';

export function setupLoginControl(successCallback) {
    document.querySelector('.login-form')?.addEventListener('submit', function (evt) {
        evt.preventDefault();

        if (document.querySelector(".login-form .has-error"))
            return;

        const errorEl = document.querySelector(".login-form .form-error");
        const postData = {
            login: evt.target.querySelector('[name=login]').value,
            password: evt.target.querySelector('[name=password]').value,
        };

        errorEl.innerHTML = '';

        ajaxPost(appBase + "Home/Logon", postData, function (data) {
            if (data.error) {
                errorEl.textContent = data.error;
                return;
            }

            successCallback(data);
        });
    });
}
