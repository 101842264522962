import $ from 'jquery.js';
import { ajaxGet } from 'network/network-old.js';

// Отправляет запрос GET на сервер и заполняет его результатами (объект с массивом Items=[(Id,Name)]) список с ID=id
function fillBootstrapSelectWithAjax(id, url, data, selectId, then, before) {
    ajaxGet(appBase + url, data, function (res) {
        $("#" + id).children('option').filter(function (_, x) { return !!$(x).val() }).remove();
        var select = $("#" + id);
        if (res && res.Items) {
            $.each(res.Items, function (index, value) {
                var option = $("<option>");
                option.attr("value", value.Id);
                option.text(value.Name);
                select.append(option);
            });
        }
        select.selectpicker("refresh");
        if (before)
            before(select, res);
        if (typeof selectId == "undefined")
            select.selectpicker("val", null);
        else {// if (select.selectpicker("val") !== selectId) {
            setSelectpickerVal(select, selectId);
        }

        //if (!select.selectpicker("val")) {
        //    let defaultOption = $("#" + id).children('option').find(function (_, x) { return !$(x).val() });
        //    if (defaultOption)
        //        defaultOption.prop('selected', true);
        //    select.selectpicker("refresh");
        //}

        if (then)
            then(select, res);
    });
}

// Устанавливает значение в списке с вызовом событий
function setSelectpickerVal($elt, val) {
    $elt.selectpicker("val", val);
    //$elt.trigger("changed.bs.select");
    //$elt.trigger("change");
}

export {
    fillBootstrapSelectWithAjax,
    setSelectpickerVal
}